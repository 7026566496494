import { ErrorHandler, Inject, Injectable, Optional } from '@angular/core'
import { ApolloError } from '@apollo/client/core'
import { SentryReporter } from './sentry-reporter.service'

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    @Inject(SentryReporter)
    @Optional()
    private sentryReporter: SentryReporter | null
  ) {
    super()
  }

  handleError(error: any): void {
    normalizeApolloNetworkError(
      // Handle unhandled rejection errors.
      error.rejection || error
    )

    if (this.sentryReporter) {
      this.sentryReporter.reportError(error)
    } else {
      super.handleError(error)
    }
  }
}

// Some network errors do not have a message, but we want to have some info in the
// overall message.
function normalizeApolloNetworkError(error: any | ApolloError) {
  if (
    error instanceof ApolloError &&
    error.networkError &&
    !error.networkError.message
  ) {
    let networkErrorJson = ''
    try {
      networkErrorJson = JSON.stringify(error.networkError)
    } catch (e) {}

    error.message = `Network error: ${networkErrorJson}`
  }
}

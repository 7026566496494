<ng-container kdghProvideParentFormGroup>
  <!-- addressLine1 -->

  <mat-form-field>
    <mat-label>Straße</mat-label>

    <input
      matInput
      maxlength="256"
      name="addressLine1"
      ngModel
      required
      type="text"
    />

    <mat-hint align="end">
      <kdgh-input-length-counter></kdgh-input-length-counter>
    </mat-hint>
  </mat-form-field>

  <!-- addressLine2 -->

  <mat-form-field>
    <mat-label>Adresszusatz</mat-label>

    <input matInput maxlength="256" name="addressLine2" ngModel type="text" />

    <mat-hint align="end">
      <kdgh-input-length-counter></kdgh-input-length-counter>
    </mat-hint>
  </mat-form-field>

  <!-- postCode -->

  <mat-form-field>
    <mat-label>PLZ</mat-label>

    <input
      matInput
      maxlength="256"
      name="postalCode"
      ngModel
      required
      type="text"
    />

    <mat-hint align="end">
      <kdgh-input-length-counter></kdgh-input-length-counter>
    </mat-hint>
  </mat-form-field>

  <!-- city -->

  <mat-form-field>
    <mat-label>Stadt</mat-label>

    <input matInput maxlength="256" name="city" ngModel required type="text" />

    <mat-hint align="end">
      <kdgh-input-length-counter></kdgh-input-length-counter>
    </mat-hint>
  </mat-form-field>

  <!-- area -->

  <mat-form-field>
    <mat-label>Region (z.b. Bundesland)</mat-label>

    <input matInput maxlength="256" name="area" ngModel type="text" />

    <mat-hint align="end">
      <kdgh-input-length-counter></kdgh-input-length-counter>
    </mat-hint>
  </mat-form-field>

  <!-- country -->

  <mat-form-field>
    <mat-label>Land</mat-label>

    <input
      matInput
      maxlength="256"
      name="country"
      ngModel
      required
      type="text"
    />

    <mat-hint align="end">
      <kdgh-input-length-counter></kdgh-input-length-counter>
    </mat-hint>
  </mat-form-field>
</ng-container>

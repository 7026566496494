import { DOCUMENT } from '@angular/common'
import { Component, ElementRef, Inject, ViewChild } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'
import { chunk } from 'lodash-es'

/**
 * The number of emails after wich a separator is inserted.
 */
const chunkSize = 40

@Component({
  selector: 'kdgh-email-list-dialog',
  templateUrl: './email-list-dialog.component.html',
  styleUrls: ['./email-list-dialog.component.scss'],
})
export class EmailListDialogComponent {
  /**
   * The separators the user can choose from to separate emails.
   */
  separators = [',', ';']

  /**
   * The currently selected separator from {@link separators}.
   */
  separator = ','

  /**
   * The {@link emails} in chunks of {@link chunkSize}.
   */
  get emailChunks(): string[][] {
    return chunk(this.emails, chunkSize)
  }

  /**
   * Ref to the dom element which displays the emails.
   */
  @ViewChild('emailsEl', { static: true })
  emailsEl: ElementRef<HTMLPreElement>

  constructor(
    @Inject(MAT_DIALOG_DATA) private emails: string[],
    @Inject(DOCUMENT) private doc: Document
  ) {}

  /**
   * Select a separator to concat {@link emails} with.
   */
  selectSeparator(separator: string) {
    this.separator = separator
  }

  /**
   * Copies the emails into the clipboard.
   */
  copyEmailsIntoClipBoard() {
    const range = new Range()
    range.selectNode(this.emailsEl.nativeElement)

    const selection = this.doc.getSelection()
    selection.removeAllRanges()
    selection.addRange(range)

    document.execCommand('copy')
  }
}

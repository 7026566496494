<mat-dialog-content>
  <h2 class="mat-h2" fxLayout>
    <mat-icon class="error-icon" color="warn">error </mat-icon>
    Fehler
  </h2>

  <ng-container [ngTemplateOutlet]="template"></ng-container>
</mat-dialog-content>

<mat-dialog-actions>
  <span fxFlex></span>

  <button mat-button matDialogClose>Schließen</button>
</mat-dialog-actions>

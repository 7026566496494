import {
  HttpClient,
  HttpEvent,
  HttpParams,
  HttpResponse,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'

export interface ImportPersonsResponse {
  personsCreated: number
  personsExcluded: number
  ids: string[]
}

export interface FileDownloadResponse {
  filename: string
  blob: Blob
}

export enum ExpenseReportOptionalData {
  PersonHomeAddress = 'PersonHomeAddress',
  Nominal = 'Nominal',
  Discount = 'Discount',
  Due = 'Due',
  PayedByMethod = 'PayedByMethod',
  Balance = 'Balance',
  ReceiptId = 'ReceiptId',
}

export interface ExpenseReportConfig {
  personTotals?: boolean
  globalTotals?: boolean
  optionalData?: ExpenseReportOptionalData[]
}

export enum PersonListData {
  Id = 'Id',
  GivenName = 'GivenName',
  FamilyName = 'FamilyName',
  Email = 'Email',
  Phone = 'Phone',
  PersonHomeAddress = 'PersonHomeAddress',
  Signature = 'Signature',
}

export interface PersonListConfig {
  include?: PersonListData[]
}

@Injectable({
  providedIn: 'root',
})
export class KdghRestApiService {
  private base = environment.apiBase

  constructor(private http: HttpClient) {}

  importPersons(file: File): Observable<ImportPersonsResponse> {
    const formData = new FormData()
    formData.append('file', file, file.name)

    return this.http.post<ImportPersonsResponse>(
      `${this.base}/persons/import`,
      formData
    )
  }

  getCourseAdminFileExpenseReceiptFile(adminFileId: string, receiptId: string) {
    return this.getFile(
      getCourseAdminFileExpenseReceiptFileEndpoint(adminFileId, receiptId)
    )
  }

  getCourseAdminFileParticipationCertificateDocument(adminFileId: string) {
    return this.getFile(
      getCourseAdminFileParticipationCertificateDocumentEndpoint(adminFileId)
    )
  }

  getCourseAdminGroupExpenseReport(
    adminGroup: string,
    config: ExpenseReportConfig
  ) {
    return this.getFile(
      getCourseAdminGroupExpenseReportEndpoint(adminGroup),
      config as any
    )
  }

  getCourseAdminGroupPersonList(adminGroup: string, config: PersonListConfig) {
    return this.getFile(
      getCourseAdminGroupPersonListEndpoint(adminGroup),
      config as any
    )
  }

  getCourseAdminGroupParticipationCertificates(adminGroup: string) {
    return this.getFile(
      getCourseAdminGroupParticipationCertificatesEndpoint(adminGroup)
    )
  }

  getCourseParticipationCertificateDocument(certificateId: string) {
    return this.getFile(
      getCourseParticipationCertificateDocumentEndpoint(certificateId)
    )
  }

  createCourseAdminGroupExpenseReceipts(adminGroupId: string) {
    return this.http
      .post(
        `${this.base}/${generateCourseAdminGroupExpenseReceiptsEndpoint(
          adminGroupId
        )}`,
        null,
        {
          observe: 'response',
          responseType: 'blob',
        }
      )
      .pipe(
        map(response => {
          if (response.status === 409) {
            return null
          }

          return {
            filename: decodeURIComponent(response.headers.get('Filename')),
            blob: response.body,
          }
        })
      )
  }

  createDocument(organizationId: string, file: File): Promise<string> {
    return this.postFile(getCreateDocumentEndpoint(organizationId), file)
      .toPromise()
      .then(event => {
        const response = event as HttpResponse<any>
        if (response.ok) {
          return response.body.id
        }
        throw response
      })
  }

  getDocumentFile(documentId: string) {
    return this.getFile(getDocumentFileEndpoint(documentId))
  }

  postFile(url: string, file: File): Observable<HttpEvent<any>> {
    const formData = new FormData()
    formData.append('file', file, file.name)

    return this.http.post(`${this.base}/${url}`, formData, {
      reportProgress: true,
      observe: 'events',
    })
  }

  getFile(
    url: string,
    params?:
      | HttpParams
      | {
          [param: string]: string | string[]
        }
  ): Observable<FileDownloadResponse> {
    return this.http
      .get(`${this.base}/${url}`, {
        observe: 'response',
        responseType: 'blob',
        params,
      })
      .pipe(
        map(response => {
          return {
            filename: decodeURIComponent(response.headers.get('Filename')),
            blob: response.body,
          }
        })
      )
  }

  deleteFile(url: string) {
    return this.http.delete(`${this.base}/${url}`)
  }
}

export function getCourseAdminFileExpenseReceiptFileEndpoint(
  adminFileId: string,
  receiptId: string
) {
  return `courseAdminFiles/${adminFileId}/expenseReceipts/${receiptId}/file`
}
export function getCourseAdminFileParticipationCertificateDocumentEndpoint(
  adminFileId: string
) {
  return `courseAdminFiles/${adminFileId}/courseParticipationCertificate/document`
}

export function getCourseAdminGroupEndpoint(adminGroupId: string) {
  return `courseAdminGroups/${adminGroupId}`
}

export function getCourseAdminGroupExpenseReportEndpoint(adminGroupId: string) {
  return `${getCourseAdminGroupEndpoint(adminGroupId)}/expenseReport`
}

export function getCourseAdminGroupPersonListEndpoint(adminGroupId: string) {
  return `${getCourseAdminGroupEndpoint(adminGroupId)}/personList`
}

export function getCourseAdminGroupParticipationCertificatesEndpoint(
  adminGroupId: string
) {
  return `${getCourseAdminGroupEndpoint(
    adminGroupId
  )}/participationCertificates`
}

export function generateCourseAdminGroupExpenseReceiptsEndpoint(
  adminGroupId: string
) {
  return `${getCourseAdminGroupEndpoint(adminGroupId)}/expenseReceipts/generate`
}

export function getUploadDataProcessingFormEndpoint(personId: string) {
  return `persons/${personId}/dataProcessingAgreements/form`
}

export function getDpaChangeEventEndpoint(eventId: string) {
  return `persons/dataProcessingAgreementChangeEvents/${eventId}/documentation`
}

export function getDataProcessingFormEndpoint(
  personId: string,
  agreementId: string
) {
  return `persons/${personId}/dataProcessingAgreements/${agreementId}/form`
}

export function getCourseParticipationCertificateDocumentEndpoint(
  certificateId: string
) {
  return `courseParticipationCertificates/${certificateId}/document`
}

export function getOrganizationMembershipAttachmentEndpoint(
  membershipId: string
) {
  return `organizationMemberships/${membershipId}/attachment`
}

export function getCreateDocumentEndpoint(organizationId: string) {
  return `documents?organizationId=${organizationId}`
}

export function getDocumentFileEndpoint(documentId: string) {
  return `documents/${documentId}/file`
}

import { Environment } from './environment.interface'

// noinspection SpellCheckingInspection
export const environment: Environment = {
  appName: 'kdgh',
  production: true,
  auth: {
    clientID: 'JjqpFjvGRBUhkxSvAKEwuhQupzPgWWJF',
    domain: 'kdgh-admin-staging.eu.auth0.com',
    apiIdentifier: 'https://staging.api.admin.kdgh.de',
    authorizeRedirectUri: 'https://staging.admin.kdgh.de/auth/callback',
    logoutRedirectUri: 'https://staging.admin.kdgh.de',
    maxInactivityInMinutes: 60,
  },
  apiBase: 'https://staging.api.admin.kdgh.de/api',
  sentry: {
    dsn: 'https://9895b85439b544a4a5a7b03354420a8e@sentry.io/1315538',
    environment: 'staging',
  },
}

import { Injectable } from '@angular/core'
import * as Sentry from '@sentry/browser'
import { defaultIntegrations, Integrations } from '@sentry/browser'
import { filter } from 'rxjs/operators'
import { BUILD_INFO } from '../../../environments/build-info'
import { environment as env } from '../../../environments/environment'
import { AuthService } from '../auth'

@Injectable()
export class SentryReporter {
  constructor(private authService: AuthService) {
    // Auth0 SDK has some kind of issues where simultaneous
    // requests to get an access token stall for 10 seconds.
    // By delaying this setup of the user context we can avoid
    // this issue.
    setTimeout(() => this.setupUserContext(), 500)
  }

  reportError(error: any) {
    const eventId = Sentry.captureException(error.originalError || error)
    Sentry.showReportDialog({ eventId })
  }

  static initSentrySdk() {
    const { dsn, environment } = env.sentry
    const { name, version, rev } = BUILD_INFO

    Sentry.init({
      dsn,
      environment,
      release: `${name}@${version}-${rev.substring(0, 6)}-${environment}`,
      // Disable the TryCatch integration, which instruments timers and events, to prevent
      // errors from being reported twice.
      // Angulars integrations with zone.js already covers them and directs errors to the global
      // error handler.
      defaultIntegrations: defaultIntegrations.filter(
        i => !(i instanceof Integrations.TryCatch)
      ),
    })
  }

  private setupUserContext() {
    this.authService.userAccount
      .pipe(filter(user => !!user))
      .subscribe(user => {
        Sentry.configureScope(scope =>
          scope.setUser({
            id: user.id,
            email: user.person.email.emailAddress,
            username: user.person.displayName,
          })
        )
      })
  }
}

<div class="control-item">
  <strong>{{ connection.pageInfo.totalEdges }}</strong> {{ entityType }}
</div>

<div class="control-spacer"></div>

<div class="control-item">
  <button
    (click)="previousPage()"
    [disabled]="!connection.pageInfo.hasPreviousPage"
    class="prev-btn"
    mat-icon-button
  >
    <mat-icon>keyboard_arrow_left</mat-icon>
  </button>
</div>

<div *ngIf="connection.pageInfo.totalEdges !== 0" class="control-item">
  {{ connection.pageInfo.page + 1 }}/{{ connection.pageInfo.totalPages }}
</div>

<div class="control-item">
  <button
    (click)="nextPage()"
    [disabled]="!connection.pageInfo.hasNextPage"
    class="next-btn"
    mat-icon-button
  >
    <mat-icon>keyboard_arrow_right</mat-icon>
  </button>
</div>

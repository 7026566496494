import {
  Directive,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core'
import {
  FormControlDirective,
  FormGroupDirective,
  NgForm,
} from '@angular/forms'
import { MatAnchor, MatButton } from '@angular/material/button'
import { MatListItem } from '@angular/material/list'
import { MatMenuItem } from '@angular/material/menu'
import { Subscription } from 'rxjs'
import {
  AuthServiceUserAccountFragment,
  UserRole,
} from '../../../generated/graphql'
import { AuthService } from '../../core/auth'

@Directive({
  selector: '[kdghEnableFor]',
})
export class EnableForDirective implements OnChanges, OnInit, OnDestroy {
  @Input()
  kdghEnableFor: string[]

  @Input()
  andIf = true

  @Input()
  orIf = false

  private userAccount: AuthServiceUserAccountFragment
  private sub: Subscription

  constructor(
    @Optional() @Self() private ngForm: NgForm,
    @Optional() @Self() private formGroupDirective: FormGroupDirective,
    @Optional() @Self() private formControlDirective: FormControlDirective,
    @Optional() @Self() private matButton: MatButton,
    @Optional() @Self() private matAnchor: MatAnchor,
    @Optional() @Self() private matMenuItem: MatMenuItem,
    @Optional() @Self() private matListItem: MatListItem,
    private authService: AuthService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.userAccount) {
      this.update()
    }
  }

  ngOnInit(): void {
    this.sub = this.authService.userAccount.subscribe(adminAppUser => {
      this.userAccount = adminAppUser
      this.update()
    })
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  private update() {
    setTimeout(() => {
      const hasRole = this.userAccount.person.adminAppUser.isArchived
        ? false
        : this.kdghEnableFor.some(role =>
            this.userAccount.person.adminAppUser.roles.includes(
              role as UserRole
            )
          )
      const isEnabled = this.orIf || (this.andIf && hasRole)

      const formControl =
        this.ngForm ?? this.formGroupDirective ?? this.formControlDirective
      if (formControl) {
        if (isEnabled) {
          formControl.form.enable()
        } else {
          formControl.form.disable()
        }
      }

      const materialComponent =
        this.matButton ?? this.matAnchor ?? this.matMenuItem ?? this.matListItem
      if (materialComponent) {
        materialComponent.disabled = !isEnabled
      }
    })
  }
}

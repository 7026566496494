import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthCallbackPageComponent, IsAuthenticatedGuard } from './core/auth'
import { HomeRouteComponent } from './core/home-route/home-route.component'
import { LayoutComponent } from './core/layout/layout.component'
import { NotFoundRouteComponent } from './core/not-found-route/not-found-route.component'

const routes: Routes = [
  { path: 'auth/callback', component: AuthCallbackPageComponent },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [IsAuthenticatedGuard],
    children: [
      {
        path: 'organizations',
        loadChildren: () =>
          import('./organizations/organizations.module').then(
            m => m.OrganizationsModule
          ),
      },
      {
        path: 'persons',
        loadChildren: () =>
          import('./persons/persons.module').then(m => m.PersonsModule),
      },
      {
        path: 'auditing',
        loadChildren: () =>
          import('./auditing/auditing.module').then(m => m.AuditingModule),
      },
      {
        path: '',
        component: HomeRouteComponent,
      },
      {
        path: '**',
        component: NotFoundRouteComponent,
      },
    ],
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      relativeLinkResolution: 'corrected',
      paramsInheritanceStrategy: 'always',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

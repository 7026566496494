import { Injectable } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router'
import { from, Observable } from 'rxjs'
import { tap } from 'rxjs/operators'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticatedGuard implements CanActivate {
  constructor(private authService: AuthService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return from(this.authService.isAuthenticated()).pipe(
      tap(isAuthenticated => {
        if (!isAuthenticated) {
          setTimeout(() => this.authService.signIn({ redirectTo: state.url }))
        }
      })
    )
  }
}

import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import {
  ErrorHandler,
  LOCALE_ID,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core'
import { environment } from '../../environments/environment'
import { OrganizationModule } from '../common/organization'
import { SharedModule } from '../shared'
import { GraphQLModule } from './api/graphql.module'
import {
  AccessTokenInterceptor,
  AuthCallbackPageComponent,
  authServiceProvider,
} from './auth'
import {
  ErrorDialogComponent,
  GlobalErrorHandler,
  SentryReporter,
} from './error'
import { HomeRouteComponent } from './home-route/home-route.component'
import { ActivityInterceptor } from './layout/activity.interceptor'
import { LayoutComponent } from './layout/layout.component'
import { OrganizationMenuComponent } from './layout/organization-menu/organization-menu.component'
import { NotFoundRouteComponent } from './not-found-route/not-found-route.component'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    GraphQLModule,
    SharedModule,
    OrganizationModule,
  ],
  declarations: [
    LayoutComponent,
    OrganizationMenuComponent,
    AuthCallbackPageComponent,
    ErrorDialogComponent,
    NotFoundRouteComponent,
    HomeRouteComponent,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useExisting: ActivityInterceptor,
    },
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useExisting: AccessTokenInterceptor,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    environment.production ? SentryReporter : [],
    authServiceProvider(),
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parent: CoreModule) {
    throwIfAlreadyLoaded(parent)
  }
}

function throwIfAlreadyLoaded(parent: CoreModule) {
  if (parent) {
    throw new Error(
      `CoreModule has already been loaded. Only import CoreModule in the root AppModule.`
    )
  }
}

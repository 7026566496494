import { Component, Input } from '@angular/core'
import { BetterError } from '../../../utils'

type JsonValue = string | number | boolean | null | JsonArray | JsonObject

type JsonArray = JsonValue[]

interface JsonObject {
  [key: string]: JsonValue
}

enum JsonValueType {
  String,
  Number,
  Boolean,
  Null,
  Array,
  Object,
}

@Component({
  selector: 'kdgh-json-value',
  templateUrl: './json-value.component.html',
  styleUrls: ['./json-value.component.scss'],
})
export class JsonValueComponent {
  @Input()
  json: JsonValue

  get valueType(): JsonValueType {
    return jsonValueType(this.json)
  }

  get entries(): [string, JsonValue][] {
    return Object.entries(this.json)
  }

  Types = JsonValueType

  isBlockValue(value: JsonValue): boolean {
    switch (jsonValueType(value)) {
      case JsonValueType.Array:
        return (value as []).length !== 0
      case JsonValueType.Object:
        return true
      default:
        return false
    }
  }
}

function jsonValueType(value: JsonValue): JsonValueType {
  switch (typeof value) {
    case 'string':
      return JsonValueType.String
    case 'number':
      return JsonValueType.Number
    case 'boolean':
      return JsonValueType.Boolean
    case 'object':
      if (value === null) {
        return JsonValueType.Null
      }

      if (Array.isArray(value)) {
        return JsonValueType.Array
      }

      return JsonValueType.Object
  }

  throw new BetterError(`Value of unexpected type`, value)
}

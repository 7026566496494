/**
 * An Error which improves on the native {@link Error}.
 */
import { Observable, OperatorFunction, throwError } from 'rxjs'
import { catchError } from 'rxjs/operators'

export class BetterError extends Error {
  /**
   * The error which caused this error, or `null` if it has no cause.
   */
  cause: any | null

  constructor(message?: string, cause?: any) {
    super(message)

    // Fix the prototype.
    Object.setPrototypeOf(this, BetterError.prototype)

    this.cause = cause || null
  }

  /**
   * Returns a function wich wraps an error with a {@link BetterError}.
   *
   * The caught error will become the {@link BetterError.cause} of the thrown error.
   *
   * @param message - The message to give to the wrapping error, or a function which receives the
   * causing error and returns the message.
   */
  static wrap(
    message: string | ((err: any) => string)
  ): (err: any) => BetterError {
    return e => {
      throw new BetterError(
        typeof message === 'string' ? message : message(e),
        e
      )
    }
  }
}

/**
 * Wraps an error, emitted from an {@link Observable}, in a {@link BetterError}.
 *
 * The caught error will become the {@link BetterError.cause} of the thrown error.
 *
 * @param message - The message to give to the wrapping error, or a function which receives the
 * causing error and returns the message.
 */
export function wrapError<T>(
  message: string | ((err: any) => string)
): OperatorFunction<T, T> {
  return catchError(err =>
    throwError(
      new BetterError(typeof message === 'string' ? message : message(err), err)
    )
  )
}

export type AsType<T, R> = T extends { __typename?: R } ? T : never

export function assertType<
  T extends { __typename?: string },
  R extends T['__typename']
>(value: T, type: R): AsType<T, R> {
  if (value.__typename !== type) {
    throw new Error(`Expected type ${type} but got ${value.__typename}`)
  }

  return value as any
}

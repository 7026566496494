<h1 matDialogTitle>E-Mails</h1>

<mat-button-toggle-group class="m-v-1">
  <mat-button-toggle
    *ngFor="let sep of separators"
    [checked]="sep === separator"
    (click)="selectSeparator(sep)"
  >
    {{ sep }}
  </mat-button-toggle>
</mat-button-toggle-group>

<mat-dialog-content>
  <div class="emails" #emailsEl>
    <ng-container *ngFor="let chunk of emailChunks; let last = last">
      <span class="email" *ngFor="let email of chunk"
        >{{ email }}{{ separator }}</span
      >
      <ng-container *ngIf="!last">
        <br />
        <br />
      </ng-container>
    </ng-container>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="copyEmailsIntoClipBoard()">
    Kopieren
  </button>
  <span fxFlex></span>
  <button mat-flat-button matDialogClose>Schließen</button>
</mat-dialog-actions>

<h1 class="mat-h1">Löschen</h1>

<mat-dialog-content>
  <p class="deletion-warning">
    Sind Sie sicher, dass Sie diese Daten löschen möchten? Das Löschen ist
    <strong>endgültig</strong>.
  </p>

  <p class="deletion-warngin" *ngIf="data.details as details">
    <ng-template [ngTemplateOutlet]="details"></ng-template>
  </p>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="onDelete()" color="warn" mat-raised-button>Löschen</button>

  <button mat-button mat-dialog-close #cancelBtn>Abbrechen</button>
</mat-dialog-actions>

import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core'
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms'
import { toNumber } from 'lodash-es'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[ngModel][max][type="number"]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: MaxDirective, multi: true },
  ],
})
export class MaxDirective implements Validator, OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('max') max: number | string

  onChange = () => {}

  ngOnChanges(changes: SimpleChanges): void {
    this.onChange()
  }

  registerOnValidatorChange(fn: () => void): void {
    this.onChange = fn
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return Validators.max(toNumber(this.max))(control)
  }
}

import { HttpErrorResponse } from '@angular/common/http'
import { ApolloError } from '@apollo/client/core'
import { GraphQLError } from 'graphql'
import { get } from 'lodash-es'
import {
  EMPTY,
  MonoTypeOperatorFunction,
  Observable,
  ObservableInput,
  ObservedValueOf,
  OperatorFunction,
  throwError,
} from 'rxjs'
import { catchError } from 'rxjs/operators'

export function isAPIError(err: GraphQLError, code: string) {
  if (err.extensions && err.extensions.code === code) {
    return true
  }
}

export function findAPIError(err: ApolloError, code: string) {
  return err.graphQLErrors.find(gqlErr => isAPIError(gqlErr, code))
}

export function catchAPIError<T, O extends ObservableInput<any>>(
  code: string,
  handler: (graphQLError: GraphQLError, apolloError: ApolloError) => void | O
): OperatorFunction<T, T | ObservedValueOf<O>> {
  return source =>
    source.pipe(
      catchError(err => {
        const apiError = findAPIError(err, code)

        if (apiError) {
          return handler(apiError, err) || EMPTY
        }

        return throwError(err)
      })
    )
}

export interface ApiError {
  message: string
  code: string
}

export function findRestApiError(error, code: string): ApiError | null {
  if (error instanceof HttpErrorResponse) {
    if (get(error.error, 'code') === code) {
      return error.error
    }
  }

  return null
}

export function catchRestAPIError<T>(
  code: string,
  handler: (error: ApiError) => void | Observable<T>
): MonoTypeOperatorFunction<T> {
  return source =>
    source.pipe(
      catchError(err => {
        const apiError = findRestApiError(err, code)

        if (apiError) {
          return handler(apiError) || EMPTY
        }

        return throwError(err)
      })
    )
}

<ng-content select="kdgh-page-entity-type"></ng-content>

<div class="header">
  <div class="title">
    <ng-content select="kdgh-page-title"></ng-content>
    <ng-content select="kdgh-page-subtitle"></ng-content>
  </div>
  <ng-content select="kdgh-page-actions"></ng-content>
</div>

<ng-content></ng-content>

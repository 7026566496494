import { Component, Inject, TemplateRef } from '@angular/core'
import { MAT_DIALOG_DATA } from '@angular/material/dialog'

@Component({
  selector: 'kdgh-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss'],
})
export class ErrorDialogComponent {
  template: TemplateRef<any>

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { template: TemplateRef<any> }
  ) {
    this.template = data.template
  }
}

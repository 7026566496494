<form (ngSubmit)="submit()" [kdghEnableFor]="['Admin']">
  <h1 class="mat-h1">
    <ng-container *ngIf="isNew"> Neue Organisation </ng-container>
    <ng-container *ngIf="!isNew">
      {{ org?.name }}
    </ng-container>
  </h1>

  <mat-dialog-content>
    <kdgh-organization-form> </kdgh-organization-form>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button
      color="primary"
      mat-raised-button
      type="submit"
      [kdghEnableFor]="['Admin']"
    >
      OK
    </button>

    <button mat-button mat-dialog-close type="button">Abbrechen</button>
  </mat-dialog-actions>
</form>

<a *ngIf="url; else content" [routerLink]="url" class="unstyled-link">
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</a>

<ng-template #content>
  <div class="resource-type-name nowrap-line">
    <kdgh-node-typename [typename]="data.__typename"> </kdgh-node-typename>
  </div>

  <div class="resource-title">
    <div class="resource-type-icon" *ngIf="icon">
      <mat-icon>{{ icon }}</mat-icon>
    </div>

    <div class="resource-name nowrap-line" *ngIf="displayName">
      {{ displayName }}
    </div>
  </div>

  <div class="resource-id nowrap-line"># {{ data.id }}</div>
</ng-template>

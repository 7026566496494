import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { EmailListDialogComponent } from './email-list-dialog.component'

@Injectable({ providedIn: 'root' })
export class EmailListDialogService {
  constructor(private dialog: MatDialog) {}

  open(emails: string[]): void {
    this.dialog.open(EmailListDialogComponent, {
      data: emails,
      maxWidth: '600px',
      width: 'calc(100vw - 2rem)',
    })
  }
}

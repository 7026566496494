import { Directive, Input, TemplateRef } from '@angular/core'

@Directive({
  selector: '[kdghNavTabLink]',
})
export class NavTabLinkDirective {
  @Input('kdghNavTabLink')
  link: any

  constructor(public content: TemplateRef<void>) {}
}

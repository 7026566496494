import { Component, Inject, OnInit, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { InternalRefetchQueriesInclude } from '@apollo/client/core'
import { map } from 'rxjs/operators'
import {
  CreateOrganizationGQL,
  OrganizationSettingsDialogCompFragment,
  OrganizationSettingsDialogGQL,
  UpdateOrganizationGQL,
} from '../../../../generated/graphql'
import { catchAPIError } from '../../../core/api'
import { deepBlankToNull } from '../../../utils'
import { OrganizationFormComponent } from '../organization-form/organization-form.component'
import { OrganizationSettingsDialogRef } from './organization-settings-dialog.service'

export interface OrganizationSettingsDialogComponentConfig {
  organizationId?: string
  refetchQueries: InternalRefetchQueriesInclude
}

@Component({
  selector: 'kdgh-organization-settings-dialog',
  templateUrl: './organization-settings-dialog.component.html',
  styleUrls: ['./organization-settings-dialog.component.scss'],
})
export class OrganizationSettingsDialogComponent implements OnInit {
  @ViewChild(NgForm, { static: true })
  ngForm: NgForm

  @ViewChild(OrganizationFormComponent, { static: true })
  form: OrganizationFormComponent

  org: OrganizationSettingsDialogCompFragment

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private config: OrganizationSettingsDialogComponentConfig,
    @Inject(MatDialogRef)
    private matDialogRef: OrganizationSettingsDialogRef,
    private query: OrganizationSettingsDialogGQL,
    private createMutation: CreateOrganizationGQL,
    private updateMutation: UpdateOrganizationGQL
  ) {}

  get isNew() {
    return !this.config.organizationId
  }

  ngOnInit() {
    if (!this.isNew) {
      this.query
        .fetch({ id: this.config.organizationId })
        .pipe(map(({ data: { node } }) => node))
        .subscribe(org =>
          setTimeout(() => {
            this.org = org as OrganizationSettingsDialogCompFragment
            this.ngForm.form.patchValue(org)
          })
        )
    }
  }

  submit() {
    if (this.ngForm.invalid) {
      return
    }

    const settings = deepBlankToNull(this.ngForm.value)

    if (this.isNew) {
      this.createMutation
        .mutate(
          { input: settings },
          { refetchQueries: this.config.refetchQueries }
        )
        .pipe(
          map(
            ({
              data: {
                createOrganization: { id },
              },
            }) => id
          ),
          catchAPIError('Conflict', () => this.form.setNameNotUniqueError())
        )
        .subscribe(id => this.matDialogRef.close(id))
    } else {
      this.updateMutation
        .mutate(
          {
            input: {
              id: this.config.organizationId,
              ...settings,
            },
          },
          { refetchQueries: this.config.refetchQueries }
        )
        .pipe(
          map(
            ({
              data: {
                updateOrganization: { id },
              },
            }) => id
          ),
          catchAPIError('Conflict', () => this.form.setNameNotUniqueError())
        )
        .subscribe(id => this.matDialogRef.close())
    }
  }
}

<kdgh-centered-page>
  <kdgh-page-title> Seite nicht gefunden </kdgh-page-title>

  <kdgh-page-subtitle>
    Die angefragte Seite konnte nicht gefunden werden.
  </kdgh-page-subtitle>

  <p>Sie sind möglicherweise hier weil:</p>

  <ul>
    <li>die Seite verschoben wurde,</li>
    <li>die Seite nicht meht existiert oder</li>
    <li>die Internetadresse inkorrekt ist.</li>
  </ul>
</kdgh-centered-page>

import { Component, forwardRef, OnInit } from '@angular/core'
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms'
import { isObject } from 'lodash-es'
import { asapScheduler, Observable, of, scheduled } from 'rxjs'
import { concatAll, map, switchMap, tap } from 'rxjs/operators'
import {
  PersonPickerByIdGQL,
  PersonPickerGQL,
  PersonPickerPersonFragment,
} from '../../../../generated/graphql'

export function personPickerAccessorBinding() {
  return {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PersonPickerComponent),
    multi: true,
  }
}

@Component({
  selector: 'kdgh-person-picker',
  templateUrl: './person-picker.component.html',
  styleUrls: ['./person-picker.component.scss'],
  providers: [personPickerAccessorBinding()],
})
export class PersonPickerComponent implements OnInit, ControlValueAccessor {
  inputControl = new FormControl(null)

  searchResults: Observable<PersonPickerPersonFragment[]>

  private inputValueObs: Observable<
    string | PersonPickerPersonFragment | null
  > = scheduled(
    [
      [this.inputControl.value],
      this.inputControl.valueChanges.pipe(map(() => this.inputControl.value)),
    ],
    asapScheduler
  ).pipe(concatAll())

  constructor(
    private personPickerGQL: PersonPickerGQL,
    private personPickerById: PersonPickerByIdGQL
  ) {}

  ngOnInit() {
    this.searchResults = this.inputValueObs.pipe(
      tap(value => {
        setTimeout(() => {
          if (isObject(value)) {
            this.onChange(value.id)
          } else {
            this.onChange(null)
          }
        })
      }),
      switchMap(value => {
        if (!value) {
          return of([])
        }

        if (typeof value === 'string') {
          return this.personPickerGQL
            .fetch({ query: value as string })
            .pipe(map(({ data: { personSearch } }) => personSearch))
        }

        // Value is object and therefore a selected person
        return of([])
      })
    )
  }

  displayFn(person?: PersonPickerPersonFragment) {
    if (!person) {
      return ''
    }
    return person.displayName || person.id
  }

  // region ControlValueAccessor

  onChange = (personId: string) => {}

  onTouched = () => {}

  registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputControl.disable()
    } else {
      this.inputControl.enable()
    }
  }

  writeValue(personId?: string): void {
    if (personId) {
      this.personPickerById
        .fetch({ id: personId })
        .pipe(map(({ data: { node } }) => node))
        .subscribe(person => this.inputControl.reset(person))
    } else {
      this.inputControl.reset(null)
    }
  }

  // endregion
}

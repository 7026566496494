import {
  HttpEvent,
  HttpEventType,
  HttpUploadProgressEvent,
} from '@angular/common/http'
import { Observable } from 'rxjs'
import { filter, map } from 'rxjs/operators'

export function uploadProgress() {
  return (obs: Observable<HttpEvent<any>>) =>
    obs.pipe(
      filter(
        (event): event is HttpUploadProgressEvent =>
          event.type === HttpEventType.UploadProgress
      ),
      map(event => (event.loaded / event.total) * 100)
    )
}

<ng-container [ngSwitch]="valueType">
  <span class="string-value" *ngSwitchCase="Types.String">{{ json }}</span>
  <span class="number-value" *ngSwitchCase="Types.Number">{{ json }}</span>
  <span class="boolean-value" *ngSwitchCase="Types.Boolean">{{ json }}</span>
  <span class="null-value" *ngSwitchCase="Types.Null">null</span>
  <ng-container *ngSwitchCase="Types.Array">
    <ol class="array-value" *ngIf="$any(json).length !== 0; else emptyArray">
      <li class="array-element" *ngFor="let value of $any(json)">
        <span class="array-element-marker">-&nbsp;</span>
        <kdgh-json-value [json]="$any(value)"></kdgh-json-value>
      </li>
    </ol>

    <ng-template #emptyArray>
      <span class="empty-array-value">-</span>
    </ng-template>
  </ng-container>
  <div class="object-value" *ngSwitchCase="Types.Object">
    <ul>
      <li class="object-entry" *ngFor="let entry of entries">
        <span class="object-entry-key">{{ entry[0] }}: </span>
        <div
          class="object-entry-value"
          [class.is-block-value]="isBlockValue(entry[1])"
        >
          <kdgh-json-value [json]="entry[1]"></kdgh-json-value>
        </div>
      </li>
    </ul>
  </div>
</ng-container>

import { HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { NgModule } from '@angular/core'
import {
  ApolloClientOptions,
  InMemoryCache,
  RequestHandler,
} from '@apollo/client/core'
import { onError } from '@apollo/client/link/error'
import { APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { AuthService } from '..'
import { BUILD_INFO } from '../../../environments/build-info'
import { environment } from '../../../environments/environment'
import fragmentMatcherIntrospection from '../../../generated/fragment-matcher'

const uri = `${environment.apiBase}/graphql`

export function getApolloOptions(
  httpLink: HttpLink,
  authService: AuthService
): ApolloClientOptions<any> {
  const http = httpLink.create({ uri })

  const authErrorMiddleware = onError(({ networkError }) => {
    if (networkError) {
      if ((networkError as HttpErrorResponse).status === 401) {
        authService.signOut()
      }
    }
  })

  const clientInfoMiddleware: RequestHandler = (operation, forward) => {
    const clientAwareness = operation.getContext().clientAwareness

    if (clientAwareness) {
      const headers =
        (operation.getContext().headers as HttpHeaders) || new HttpHeaders()

      const newHeaders = headers
        .append('apollographql-client-name', clientAwareness.name)
        .append('apollographql-client-version', clientAwareness.version)

      operation.setContext({
        ...operation.getContext(),
        headers: newHeaders,
      })
    }

    return forward(operation)
  }

  return {
    link: authErrorMiddleware.concat(clientInfoMiddleware).concat(http),
    cache: new InMemoryCache({
      possibleTypes: fragmentMatcherIntrospection.possibleTypes,
    }),
    name: 'KdGH Admin UI',
    version: BUILD_INFO.version,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
      },
      query: {
        fetchPolicy: 'network-only',
      },
    },
  }
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: getApolloOptions,
      deps: [HttpLink, AuthService],
    },
  ],
})
export class GraphQLModule {}

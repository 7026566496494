import { registerLocaleData } from '@angular/common'
import deLocale from '@angular/common/locales/de'
import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import moment from 'moment'
import 'moment/locale/de'
import { AppModule } from './app/app.module'
import { SentryReporter } from './app/core/error'
import { environment } from './environments/environment'

if (environment.production) {
  SentryReporter.initSentrySdk()
  enableProdMode()
}

registerLocaleData(deLocale)
moment.locale('de')

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err))

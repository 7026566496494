import { Component, ElementRef, Input, ViewChild } from '@angular/core'
import { Observable } from 'rxjs'
import { finalize, share } from 'rxjs/operators'
import { KdghRestApiService } from '../../../core'
import { downloadFile, uploadProgress } from '../../../core/api'
import { SubSink } from '../../../utils'

export interface FileConnector {
  exists: boolean

  /**
   * Is expected to upload the given file and emit upload progress in percent (0-100).
   */
  upload(file: File): Observable<number>

  open(): void

  delete(): void
}

// TODO handle max size
@Component({
  selector: 'kdgh-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
  providers: [SubSink],
})
export class FileComponent {
  @Input()
  connector: FileConnector

  @Input()
  accept: string

  isUploading: boolean

  progress: number

  @ViewChild('fileInput', { static: true })
  fileInput: ElementRef<HTMLInputElement>

  constructor(private subs: SubSink) {}

  onFileInputChange() {
    const inputEl = this.fileInput.nativeElement
    const file = inputEl.files.item(0)

    inputEl.value = ''

    this.isUploading = true
    this.progress = 0

    const progress$ = this.connector.upload(file).pipe(share())

    this.subs.sink = progress$
      .pipe(
        // Let the progressbar finish animating to 100
        finalize(() => setTimeout(() => (this.isUploading = false), 500))
      )
      .subscribe(progress => (this.progress = progress))
  }
}

export class RestApiFileConnector implements FileConnector {
  constructor(
    private getUrl: () => string,
    public getExists: () => boolean,
    private onChange: () => void,
    private restApi: KdghRestApiService
  ) {}

  upload(file: File): Observable<number> {
    return this.restApi.postFile(this.getUrl(), file).pipe(
      uploadProgress(),
      finalize(() => this.onChange())
    )
  }

  delete(): void {
    this.restApi.deleteFile(this.getUrl()).subscribe(() => this.onChange())
  }

  open(): void {
    this.restApi.getFile(this.getUrl()).subscribe(downloadFile)
  }

  get exists() {
    return this.getExists()
  }
}

import { NgModule } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { ServiceWorkerModule, SwUpdate } from '@angular/service-worker'
import { environment } from '../environments/environment'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { CoreModule } from './core/core.module'
import { SharedModule } from './shared'

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private swUpdate: SwUpdate, private matSnackBar: MatSnackBar) {
    if (swUpdate.isEnabled) {
      swUpdate.available.subscribe(() => {
        matSnackBar
          .open('Ein Update ist verfügbar.', 'Jetzt aktualisieren', {
            duration: 10000,
          })
          .onAction()
          .subscribe(async () => {
            await swUpdate.activateUpdate()
            window.location.reload()
          })
      })
    }
  }
}

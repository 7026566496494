import { Component, ViewChild } from '@angular/core'
import { NgModel } from '@angular/forms'

@Component({
  selector: 'kdgh-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss'],
})
export class OrganizationFormComponent {
  @ViewChild('nameControl', { static: true })
  name: NgModel

  setNameNotUniqueError() {
    this.name.control.setErrors({ notUnique: true })
  }
}

import { Component, ContentChildren, QueryList } from '@angular/core'
import { NavTabLinkDirective } from './nav-tab-link.directive'

@Component({
  selector: 'kdgh-nav-tab-bar',
  template: ` <nav mat-tab-nav-bar>
    <a
      mat-tab-link
      *ngFor="let link of links"
      [routerLink]="[link.link]"
      routerLinkActive
      #rla="routerLinkActive"
      [active]="rla.isActive"
    >
      <ng-container [ngTemplateOutlet]="link.content"></ng-container>
    </a>
  </nav>`,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class NavTabBarComponent {
  @ContentChildren(NavTabLinkDirective)
  links: QueryList<NavTabLinkDirective>
}

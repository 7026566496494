import { Injectable, Injector, Provider } from '@angular/core'
import { Router } from '@angular/router'
import { Auth0Client } from '@auth0/auth0-spa-js'
import { QueryRef } from 'apollo-angular'
import moment, { Moment } from 'moment'
import { Observable } from 'rxjs'
import { distinctUntilChanged, pluck } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import {
  AuthServiceUserAccountFragment,
  GetAuthServiceUserAccountGQL,
  GetAuthServiceUserAccountQuery,
  GetAuthServiceUserAccountQueryVariables,
} from '../../../generated/graphql'

export abstract class AuthService {
  constructor(private injector: Injector) {}

  private _userAccountQuery: QueryRef<
    GetAuthServiceUserAccountQuery,
    GetAuthServiceUserAccountQueryVariables
  >

  private get userAccountQuery(): QueryRef<
    GetAuthServiceUserAccountQuery,
    GetAuthServiceUserAccountQueryVariables
  > {
    if (!this._userAccountQuery) {
      this._userAccountQuery = this.injector
        .get(GetAuthServiceUserAccountGQL)
        .watch()
    }
    return this._userAccountQuery
  }

  get userAccount(): Observable<AuthServiceUserAccountFragment | null> {
    return this.userAccountQuery.valueChanges.pipe(
      pluck('data', 'viewerUserAccount'),
      distinctUntilChanged()
    )
  }

  async handleCallback(): Promise<void> {
    await this.performHandleCallback()
    await this.updateUser()
  }

  abstract performHandleCallback(): Promise<void>

  abstract signIn(options?: {
    redirectTo?: string
    reauthenticate?: boolean
  }): Promise<void>

  abstract signOut(): Promise<void>

  abstract getAccessToken(): Promise<string>

  abstract isAuthenticated(): Promise<boolean>

  async updateUser(): Promise<AuthServiceUserAccountFragment | null> {
    return this.userAccountQuery
      .refetch()
      .then(({ data }) => data?.viewerUserAccount ?? null)
  }
}

interface TokenResponse {
  access_token: string
  id_token: string
  scope: string
  expires_in: number
  token_type: string
}

@Injectable()
export class TestAuthService extends AuthService {
  constructor(private tokenResponse: TokenResponse, injector: Injector) {
    super(injector)
  }

  async getAccessToken(): Promise<string> {
    return this.tokenResponse.access_token
  }

  async isAuthenticated(): Promise<boolean> {
    return true
  }

  signIn(options?: {
    redirectTo?: string
    reauthenticate?: boolean
  }): Promise<void> {
    throw new Error('Not implemented.')
  }

  signOut(): Promise<void> {
    throw new Error('Not implemented.')
  }

  performHandleCallback(): Promise<void> {
    throw new Error('Not implemented.')
  }
}

@Injectable()
export class Auth0SpaAuthService extends AuthService {
  private static lastActivityDateKey = 'kdgh:lastActivityDate'

  private auth0Client: Auth0Client
  private router: Router

  constructor(injector: Injector) {
    super(injector)
    this.router = injector.get(Router)
    this.setupAuth0Client()
  }

  async signIn(options?: {
    redirectTo?: string
    reauthenticate?: boolean
  }): Promise<void> {
    this.recordActivity()

    await this.auth0Client.loginWithRedirect({
      appState: { redirectTo: options?.redirectTo ?? this.router.url },
      prompt: options?.reauthenticate ? 'login' : undefined,
    })
  }

  async signOut(): Promise<void> {
    await this.auth0Client.logout()
  }

  async performHandleCallback(): Promise<void> {
    const result = await this.auth0Client.handleRedirectCallback()

    const redirectTo = result.appState?.redirectTo ?? '/'
    await this.router.navigateByUrl(redirectTo)
  }

  async getAccessToken(): Promise<string> {
    let token: string

    if (this.userIsInactive) {
      await this.signIn({ reauthenticate: true })
    } else {
      this.recordActivity()
    }

    try {
      token = await this.auth0Client.getTokenSilently()
    } catch (e) {
      if (
        [
          'login_required',
          'consent_required',
          'interaction_required',
          'timeout',
        ].includes(e.error)
      ) {
        await this.signIn()

        // Stop this method from ever returning.
        await blockAsync()
      }

      throw e
    }

    return token
  }

  async isAuthenticated(): Promise<boolean> {
    return this.auth0Client.isAuthenticated()
  }

  private setupAuth0Client() {
    const { domain, clientID, apiIdentifier, authorizeRedirectUri } =
      environment.auth

    this.auth0Client = new Auth0Client({
      domain,
      client_id: clientID,
      audience: apiIdentifier,
      redirect_uri: authorizeRedirectUri,
      useRefreshTokens: true,
      cacheLocation: environment.production ? 'memory' : 'localstorage',
    })
  }

  private get userIsInactive(): boolean {
    const maxInactivityDate = this.maxInactivityDate
    if (maxInactivityDate === null) return false

    return moment().isAfter(maxInactivityDate)
  }

  private recordActivity() {
    this.lastActivityDate = moment()
  }

  private get lastActivityDate(): Moment | null {
    const lastActivityItem = localStorage.getItem(
      Auth0SpaAuthService.lastActivityDateKey
    )
    if (lastActivityItem === null) {
      return null
    }

    return moment(lastActivityItem)
  }

  private set lastActivityDate(value: Moment) {
    localStorage.setItem(
      Auth0SpaAuthService.lastActivityDateKey,
      value.toISOString()
    )
  }

  private get maxInactivityDate(): Moment | null {
    const {
      auth: { maxInactivityInMinutes },
    } = environment

    // If option is not set max inactivity is not enforced.
    if (!maxInactivityInMinutes) {
      return null
    }

    const lastActivityDate = this.lastActivityDate

    // There has been no activity yet.
    if (lastActivityDate === null) {
      return null
    }

    return lastActivityDate.clone().add(maxInactivityInMinutes, 'm')
  }
}

function blockAsync() {
  return new Promise(() => null)
}

export function authServiceProvider(): Provider {
  return {
    provide: AuthService,
    deps: [Injector],
    useFactory: (injector: Injector) => {
      if (!environment.production) {
        const testCredentials = (window as any).__test_credentials__
        if (testCredentials) {
          return new TestAuthService(testCredentials, injector)
        }
      }

      return new Auth0SpaAuthService(injector)
    },
  }
}

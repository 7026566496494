import { Component, Input } from '@angular/core'

@Component({
  selector: 'kdgh-node-typename',
  template: `
    <ng-container [ngSwitch]="typename">
      <ng-container *ngSwitchCase="'AuditEvent'">Audit-Event</ng-container>
      <ng-container *ngSwitchCase="'Course'">Kurs</ng-container>
      <ng-container *ngSwitchCase="'CourseAdminFile'">Kursakte</ng-container>
      <ng-container *ngSwitchCase="'CourseAdminGroup'"
        >Kursverwaltungsgruppe</ng-container
      >
      <ng-container *ngSwitchCase="'CourseEvent'"
        >Kursveranstaltung</ng-container
      >
      <ng-container *ngSwitchCase="'CourseEventAttendance'"
        >Kursveranstaltungsteilnahme</ng-container
      >
      <ng-container *ngSwitchCase="'CourseOptionDescriptor'"
        >Kursoption</ng-container
      >
      <ng-container *ngSwitchCase="'CourseOptionBooking'"
        >Kursoptionsbuchung</ng-container
      >
      <ng-container *ngSwitchCase="'CoursePart'">Kursteil</ng-container>
      <ng-container *ngSwitchCase="'CourseParticipationCertificate'"
        >Kursteilnahmebestätigung</ng-container
      >
      <ng-container *ngSwitchCase="'DataProcessingActivity'"
        >Datenverarbeitungsaktivität</ng-container
      >
      <ng-container *ngSwitchCase="'DataProcessingAgreementChangeEvent'"
        >DVE Änderungsvorgang</ng-container
      >
      <ng-container *ngSwitchCase="'DataProcessingAgreementChange'"
        >DVE Änderung</ng-container
      >
      <ng-container *ngSwitchCase="'DataProcessingProcess'"
        >Datenverarbeitungsprozess</ng-container
      >
      <ng-container *ngSwitchCase="'Expense'">Ausgabe</ng-container>
      <ng-container *ngSwitchCase="'ExpenseDescriptor'"
        >Ausgabenbeschreibung</ng-container
      >
      <ng-container *ngSwitchCase="'ExpenseReceipt'">Quittung</ng-container>
      <ng-container *ngSwitchCase="'Organization'">Organisation</ng-container>
      <ng-container *ngSwitchCase="'OrganizationMembership'"
        >Mitgliedschaft</ng-container
      >
      <ng-container *ngSwitchCase="'Payment'">Zahlung</ng-container>
      <ng-container *ngSwitchCase="'Person'">Person</ng-container>
      <ng-container *ngSwitchCase="'User'">Nutzer</ng-container>
      <ng-container *ngSwitchCase="'AdminAppUser'"
        >Nutzerkonto (Datenverarbeitung)</ng-container
      >
      <ng-container *ngSwitchCase="'MemberPortalUser'"
        >Nutzerkonto (Mitgliederportal)</ng-container
      >
      <ng-container *ngSwitchDefault>{{ typename }}</ng-container>
    </ng-container>
  `,
})
export class NodeTypenameComponent {
  @Input()
  typename: string
}

import { FileDownloadResponse } from './kdgh-rest-api.service'

export function downloadFile({ blob, filename }: FileDownloadResponse) {
  const url = URL.createObjectURL(blob)
  const a = document.createElement('a')
  a.href = url
  a.download = filename

  document.body.appendChild(a)
  a.click()

  setTimeout(() => {
    a.remove()
    URL.revokeObjectURL(url)
  })
}

<mat-form-field>
  <mat-label>Person</mat-label>

  <input
    (blur)="onTouched()"
    [formControl]="inputControl"
    [matAutocomplete]="autocomplete"
    matInput
    type="text"
  />
</mat-form-field>

<mat-autocomplete #autocomplete="matAutocomplete" [displayWith]="displayFn">
  <mat-option *ngFor="let person of searchResults | async" [value]="person">
    <div class="person-name">{{ person.displayName }}</div>
    <div *ngIf="person.email as email" class="person-email">
      {{ email?.emailAddress }}
    </div>
  </mat-option>
</mat-autocomplete>

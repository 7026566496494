import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BehaviorSubject, defer, Observable } from 'rxjs'
import { distinctUntilChanged, finalize, map, share } from 'rxjs/operators'

@Injectable({
  providedIn: 'root',
})
export class ActivityInterceptor implements HttpInterceptor {
  activeRequests = new BehaviorSubject<number>(0)

  hasActiveRequest: Observable<boolean> = this.activeRequests.pipe(
    distinctUntilChanged(),
    map(reqs => reqs !== 0)
  )

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return defer(() => {
      this.incrementActiveRequests()

      const response = next.handle(req).pipe(share())

      response.pipe(finalize(() => this.decrementActiveRequests())).subscribe({
        // Do nothing on error, just don't let it become unhandled.
        // The request initiator must handle it.
        error: () => {},
      })

      return response
    })
  }

  private incrementActiveRequests() {
    this.updateActiveRequests(activeRequests => activeRequests + 1)
  }

  private decrementActiveRequests() {
    this.updateActiveRequests(activeRequests => activeRequests - 1)
  }

  private updateActiveRequests(updater: (activeRequests: number) => number) {
    this.activeRequests.next(updater(this.activeRequests.value))
  }
}

import { AbstractControl, ValidationErrors } from '@angular/forms'
import { isString } from 'lodash-es'
import isUrl from 'validator/es/lib/isURL'
import { isBlank } from '../../utils'

export class KdghValidators {
  static notBlank(control: AbstractControl): ValidationErrors | null {
    const value = control.value

    if (!isString(value)) {
      return null
    }

    if (!isBlank(value)) {
      return null
    }

    return { kdghNotBlank: true }
  }

  static url(control: AbstractControl): ValidationErrors | null {
    const value = control.value

    if (!isString(value)) {
      return null
    }

    if (isBlank(value) || isUrl(value)) {
      return null
    }

    return { kdghUrl: true }
  }
}

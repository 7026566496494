import { Injectable, OnDestroy } from '@angular/core'
import { Subscription } from 'rxjs'

@Injectable()
export class SubSink implements OnDestroy {
  private subs: Subscription[] = []

  set sink(subscription: Subscription) {
    this.subs.push(subscription)
  }

  unsubscribe() {
    this.subs.forEach(sub => sub.unsubscribe())
    this.subs = []
  }

  ngOnDestroy(): void {
    this.unsubscribe()
  }
}

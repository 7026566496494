import { ContentChildren, Directive, QueryList } from '@angular/core'
import { NgControl } from '@angular/forms'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-form-field',
})
export class SiblingControlsDirective {
  @ContentChildren(NgControl, { descendants: true })
  ngControls: QueryList<NgControl>
}

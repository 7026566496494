import { LayoutModule } from '@angular/cdk/layout'
import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FlexLayoutModule } from '@angular/flex-layout'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { MomentModule } from 'ngx-moment'
import { EmailListDialogComponent } from './email-list-dialog/email-list-dialog.component'
import { FormFieldDescriptionComponent } from './form/form-field-description/form-field-description.component'
import { FormSectionTitleComponent } from './form/form-section-title/form-section-title.component'
import { FormSectionComponent } from './form/form-section/form-section.component'
import { IfHasErrorDirective } from './form/if-has-error.directive'
import {
  InputLengthCounterComponent,
  InputLengthCounterFormFieldDirective,
  InputLengthCounterInputDirective,
} from './form/input-length-counter/input-length-counter.component'
import { MaxDirective } from './form/max.directive'
import { MinDirective } from './form/min.directive'
import { RequiredNotBlankValidator } from './form/not-blank-validator.directive'
import { PostalAddressFormComponent } from './form/postal-address-form/postal-address-form.component'
import { ProvideParentFormGroupDirective } from './form/provide-parent-form-group.directive'
import { ProvideParentFormDirective } from './form/provide-parent-form.directive'
import { SiblingControlsDirective } from './form/sibling-controls.directive'
import { UrlDirective } from './form/url.directive'
import { MaterialModule } from './material/material.module'
import { CurrencySymbolPipe } from './mics/currency-symbol.pipe'
import {
  ConfirmDeletionDirective,
  DeleteConfirmationDialogComponent,
} from './mics/delete-confirmation-dialog/delete-confirmation-dialog.component'
import { EnableForDirective } from './mics/enable-for.directive'
import { EntityTimestampsComponent } from './mics/entity-timestamps/entity-timestamps.component'
import { FileComponent } from './mics/file/file.component'
import { FormatYearsPipe } from './mics/format-years.pipe'
import { JsonValueComponent } from './mics/json-value/json-value.component'
import { ListPagingControlsComponent } from './mics/list-paging-controls/list-paging-controls.component'
import { LoadingBannerComponent } from './mics/loading-banner/loading-banner.component'
import { MoneyComponent } from './mics/money/money.component'
import { NodeTypenameComponent } from './mics/node-typename.component'
import { PersonPickerComponent } from './mics/person-picker/person-picker.component'
import { NavTabBarComponent } from './nav-tab-bar/nav-tab-bar.component'
import { NavTabLinkDirective } from './nav-tab-bar/nav-tab-link.directive'
import { CenteredPageComponent } from './page-layout/centered-page/centered-page.component'
import { PageActionsComponent } from './page-layout/page-actions/page-actions.component'
import { PageEntityTypeComponent } from './page-layout/page-entity-type/page-entity-type.component'
import { PageSectionTitleComponent } from './page-layout/page-section-title/page-section-title.component'
import { PageSectionComponent } from './page-layout/page-section/page-section.component'
import { PageSubtitleComponent } from './page-layout/page-subtitle/page-subtitle.component'
import { PageTitleComponent } from './page-layout/page-title/page-title.component'
import { ResourceLinkComponent } from './resource-link/resource-link.component'

const commonModules = [
  CommonModule,
  MaterialModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  LayoutModule,
  MomentModule,
]

const exportDeclarations = [
  InputLengthCounterComponent,
  InputLengthCounterFormFieldDirective,
  InputLengthCounterInputDirective,
  RequiredNotBlankValidator,
  UrlDirective,
  FormatYearsPipe,
  IfHasErrorDirective,
  SiblingControlsDirective,
  LoadingBannerComponent,
  PostalAddressFormComponent,
  ProvideParentFormDirective,
  ProvideParentFormGroupDirective,
  EntityTimestampsComponent,
  FormFieldDescriptionComponent,
  DeleteConfirmationDialogComponent,
  ConfirmDeletionDirective,
  PageSectionComponent,
  FormSectionComponent,
  CenteredPageComponent,
  ListPagingControlsComponent,
  PageTitleComponent,
  PageEntityTypeComponent,
  FormSectionTitleComponent,
  PageSectionTitleComponent,
  PersonPickerComponent,
  MinDirective,
  MaxDirective,
  CurrencySymbolPipe,
  MoneyComponent,
  PageActionsComponent,
  PageSubtitleComponent,
  FileComponent,
  NodeTypenameComponent,
  JsonValueComponent,
  EnableForDirective,
  NavTabBarComponent,
  NavTabLinkDirective,
  ResourceLinkComponent,
]

@NgModule({
  imports: [...commonModules],
  exports: [...commonModules, ...exportDeclarations],
  declarations: [...exportDeclarations, EmailListDialogComponent],
})
export class SharedModule {}

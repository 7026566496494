import { NgModule } from '@angular/core'
import { SharedModule } from '../../shared'
import { OrganizationFormComponent } from './organization-form/organization-form.component'
import { OrganizationSettingsDialogComponent } from './organization-settings-dialog/organization-settings-dialog.component'
import { OrganizationService } from './organization.service'

@NgModule({
  imports: [SharedModule],
  declarations: [
    OrganizationFormComponent,
    OrganizationSettingsDialogComponent,
  ],
  providers: [OrganizationService],
})
export class OrganizationModule {}

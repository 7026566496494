import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { filter, map, tap } from 'rxjs/operators'
import {
  OrganizationMenuDocument,
  OrganizationMenuGQL,
  OrganizationMenuItemFragment,
} from '../../../../generated/graphql'
import { OrganizationService } from '../../../common/organization'

@Component({
  selector: 'kdgh-organization-menu',
  templateUrl: './organization-menu.component.html',
  styleUrls: ['./organization-menu.component.scss'],
})
export class OrganizationMenuComponent implements OnInit {
  organizations: Observable<OrganizationMenuItemFragment[]> =
    this.organizationMenuGQL.watch().valueChanges.pipe(
      map(({ data }) => data.organizations),
      tap(organizations =>
        this.organizationService.verifySelectedOrganization(
          organizations.map(org => org.id)
        )
      )
    )

  constructor(
    private organizationMenuGQL: OrganizationMenuGQL,
    public organizationService: OrganizationService
  ) {}

  ngOnInit(): void {
    this.organizationService.registerQueryForUpdate({
      query: OrganizationMenuDocument,
    })
  }

  getSelectedOrganization(
    orgs: OrganizationMenuItemFragment[]
  ): OrganizationMenuItemFragment | null {
    return orgs.find(
      org => org.id === this.organizationService.selectedOrganizationId
    )
  }

  openSettingsDialog() {
    this.organizationService
      .openNewOrganizationDialog()
      .afterClosed()
      .pipe(filter(id => !!id))
      .subscribe(id => this.organizationService.selectOrganization(id))
  }

  selectOrganization(id: string) {
    this.organizationService.selectOrganization(id)
  }
}

import { animate, style, transition, trigger } from '@angular/animations'
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout'
import { Component, OnInit } from '@angular/core'
import { Apollo } from 'apollo-angular'
import { interval, Observable } from 'rxjs'
import { debounce, map, shareReplay } from 'rxjs/operators'
import { BUILD_INFO } from 'src/environments/build-info'
import { environment } from '../../../environments/environment'
import { AuthServiceUserAccountFragment } from '../../../generated/graphql'
import { OrganizationService } from '../../common/organization'
import { SubSink } from '../../utils'
import { AuthService } from '../auth/auth.service'
import { ActivityInterceptor } from './activity.interceptor'

@Component({
  selector: 'kdgh-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  providers: [SubSink],
  animations: [
    trigger('activity', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('100ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [animate('100ms', style({ opacity: 0 }))]),
    ]),
  ],
})
export class LayoutComponent implements OnInit {
  buildInfo = BUILD_INFO

  hasActivity = this.activityInterceptor.hasActiveRequest.pipe(
    debounce(hasActiveRequests => interval(hasActiveRequests ? 0 : 750))
  )

  apiBase: string = environment.apiBase

  userAccount: AuthServiceUserAccountFragment

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    )

  constructor(
    private breakpointObserver: BreakpointObserver,
    private apollo: Apollo,
    private activityInterceptor: ActivityInterceptor,
    public authService: AuthService,
    public organizationService: OrganizationService,
    private subSink: SubSink
  ) {}

  ngOnInit() {
    this.subSink.sink = this.authService.userAccount.subscribe(adminAppUser => {
      this.userAccount = adminAppUser
    })
  }

  refresh() {
    this.apollo.client.resetStore()
  }

  async logout() {
    await this.authService.signOut()
  }
}

<mat-sidenav-container>
  <!-- Sidenav -->
  <mat-sidenav
    #drawer
    fixedInViewport
    [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
    [mode]="(isHandset$ | async) ? 'over' : 'side'"
    [opened]="(isHandset$ | async) === false"
  >
    <div class="sidenav-wrapper">
      <div class="sidenav-content">
        <mat-nav-list>
          <mat-list-item>
            <mat-icon matListIcon>business</mat-icon>
            <kdgh-organization-menu></kdgh-organization-menu>
          </mat-list-item>

          <a
            [disabled]="!organizationService.hasSelectedOrganization"
            mat-list-item
            routerLink="/organizations/{{
              organizationService.selectedOrganizationId
            }}/courses"
            routerLinkActive="is-active"
          >
            <mat-icon matListIcon>school</mat-icon> Kurse
          </a>

          <a
            [disabled]="!organizationService.hasSelectedOrganization"
            mat-list-item
            routerLink="/organizations/{{
              organizationService.selectedOrganizationId
            }}/documents"
            routerLinkActive="is-active"
          >
            <mat-icon matListIcon>text_snippet</mat-icon> Dokumente
          </a>

          <mat-divider></mat-divider>

          <a mat-list-item routerLink="/persons" routerLinkActive="is-active">
            <mat-icon matListIcon>face</mat-icon> Personen
          </a>

          <a
            mat-list-item
            routerLink="/auditing/events"
            routerLinkActive="is-active"
            [kdghEnableFor]="['Admin']"
          >
            <mat-icon matListIcon>policy</mat-icon> Aktivitäten
          </a>
        </mat-nav-list>

        <div class="flex-spacer"></div>

        <mat-divider></mat-divider>

        <mat-list dense class="app-meta-data">
          <h3 matSubheader>App Info</h3>

          <mat-list-item>
            <span class="name">Version:</span>&nbsp;
            <span class="value">{{ buildInfo.version }}</span>
          </mat-list-item>
          <mat-list-item>
            <span class="name">Datum:</span>&nbsp;
            <span class="value">{{ buildInfo.date | date: 'medium' }}</span>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </mat-sidenav>

  <!-- Content -->
  <mat-sidenav-content>
    <!-- Main Toolbar -->
    <mat-toolbar color="primary">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async"
      >
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>

      <div fxFlex></div>

      <!-- User Context Menu -->

      <ng-container *ngIf="userAccount?.person as person">
        <button
          class="user-menu-trigger"
          [matMenuTriggerFor]="userMenu"
          mat-button
        >
          {{ person.displayName }}
        </button>

        <mat-menu #userMenu>
          <a mat-menu-item routerLink="/persons/{{ person.id }}">
            Meine Daten
          </a>

          <button (click)="logout()" mat-menu-item>Abmelden</button>
        </mat-menu>
      </ng-container>

      <mat-divider [vertical]="true"></mat-divider>

      <!-- Refresh/Activity Signifier -->

      <div class="activity">
        <mat-spinner
          *ngIf="hasActivity | async; else refreshButton"
          @activity
          [diameter]="18"
          [strokeWidth]="2"
          color="accent"
        >
        </mat-spinner>

        <ng-template #refreshButton>
          <button (click)="refresh()" @activity mat-icon-button>
            <mat-icon>refresh</mat-icon>
          </button>
        </ng-template>
      </div>
    </mat-toolbar>

    <!-- App Content -->
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

import { isArray, isNil, isPlainObject, isString, mapValues } from 'lodash-es'
import isEmpty from 'validator/es/lib/isEmpty'

export function isBlank(value?: string): boolean {
  return isNil(value) || isEmpty(value, { ignore_whitespace: true })
}

export function blankToNull(value: string): string | null {
  return isBlank(value) ? null : value
}

export function deepBlankToNull<T>(value: T): T | null {
  if (isArray(value)) {
    return value.map(deepBlankToNull) as any
  }

  if (isPlainObject(value)) {
    return mapValues(value as any, deepBlankToNull)
  }

  if (isString(value)) {
    return blankToNull(value) as any
  }

  return value
}

import { ScrollStrategyOptions } from '@angular/cdk/overlay'
import { Injectable, TemplateRef } from '@angular/core'
import { MatDialog, MatDialogConfig } from '@angular/material/dialog'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ErrorDialogComponent } from './error'

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    private matSnackBar: MatSnackBar,
    private matDialog: MatDialog,
    private scrollStrategy: ScrollStrategyOptions
  ) {}

  openSuccessFromTemplate(template: TemplateRef<any>) {
    this.matSnackBar.openFromTemplate(template, {
      duration: 5_000,
      horizontalPosition: 'right',
      verticalPosition: 'top',
    })
  }

  openErrorFromTemplate(template: TemplateRef<any>) {
    const config: MatDialogConfig = {
      hasBackdrop: true,
      scrollStrategy: this.scrollStrategy.block(),
      maxWidth: '40rem',
      restoreFocus: true,
      data: { template },
    }

    const dialog = this.matDialog.open(ErrorDialogComponent, config)
  }
}

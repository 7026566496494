import { Component, ContentChild, Directive, ElementRef } from '@angular/core'
import { MatHint } from '@angular/material/form-field'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-form-field input, mat-form-field textarea',
})
export class InputLengthCounterInputDirective {
  private inputElement: HTMLInputElement

  constructor(private elementRef: ElementRef) {
    this.inputElement = elementRef.nativeElement
  }

  get currentLength(): number {
    return this.inputElement.value.length
  }

  get maxLength(): number | null {
    const maxLength = this.inputElement.maxLength
    return maxLength === -1 ? null : maxLength
  }
}

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'mat-form-field',
})
export class InputLengthCounterFormFieldDirective {
  @ContentChild(InputLengthCounterInputDirective)
  inputDirective?: InputLengthCounterInputDirective
}

@Component({
  selector: 'kdgh-input-length-counter',
  template:
    '{{currentLength}}<ng-container *ngIf="hasMaxLength">/{{maxLength}}</ng-container>',
})
export class InputLengthCounterComponent {
  constructor(
    private matHint: MatHint,
    private formFieldDirective: InputLengthCounterFormFieldDirective
  ) {}

  get currentLength(): number {
    return this.formFieldDirective.inputDirective.currentLength
  }

  get maxLength(): number | null {
    return this.formFieldDirective.inputDirective.maxLength
  }

  get hasMaxLength(): boolean {
    return this.maxLength !== null
  }
}

import {
  AfterViewInit,
  Component,
  Directive,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core'
import { MatButton } from '@angular/material/button'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'

interface DeleteConfirmationDialogData {
  details?: TemplateRef<any>
}

@Component({
  selector: 'kdgh-delete-confirmation-dialog',
  templateUrl: './delete-confirmation-dialog.component.html',
  styles: [
    `
      .deletion-warning {
        margin-bottom: 1rem;
      }
    `,
  ],
})
export class DeleteConfirmationDialogComponent implements AfterViewInit {
  @ViewChild('cancelBtn')
  cancelBtn: MatButton

  constructor(
    private dialog: MatDialogRef<void>,
    @Inject(MAT_DIALOG_DATA)
    public data: DeleteConfirmationDialogData
  ) {}

  ngAfterViewInit(): void {
    setTimeout(() => this.cancelBtn.focus('program'), 200)
  }

  onDelete() {
    this.dialog.close(true)
  }
}

@Directive({
  selector: '[kdghConfirmDeletion]',
})
export class ConfirmDeletionDirective {
  /**
   * Content which gives addition details about what the effects of deletion are.
   */
  @Input()
  kdghConfirmDeletionDetails?: TemplateRef<any>

  @Output()
  delete = new EventEmitter()

  constructor(private matDialog: MatDialog) {}

  @HostListener('click')
  onClick() {
    const dialogRef: MatDialogRef<any, boolean> = this.matDialog.open(
      DeleteConfirmationDialogComponent,
      {
        width: 'calc(100vw - 2rem)',
        maxWidth: '400px',
        data: {
          details: this.kdghConfirmDeletionDetails,
        } as DeleteConfirmationDialogData,
      }
    )

    dialogRef.afterClosed().subscribe(deletionConfirmed => {
      if (deletionConfirmed === true) {
        this.delete.emit()
      }
    })
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core'
import { PageEvent } from '@angular/material/paginator'
import { Params } from '@angular/router'
import { defaultTo } from 'lodash-es'
import { ListPagingControlsFragment } from '../../../../generated/graphql'

export interface PagingInfo {
  first?: number
  after?: string
  last?: number
  before?: string
}

export function updatePagingInfoWithPageEvent(
  pagingInfo: PagingInfo,
  connection: ListPagingControlsFragment,
  event: PageEvent
): PagingInfo {
  if (event.pageIndex === event.previousPageIndex) {
    const isPagingForward = typeof pagingInfo.first === 'number'
    return isPagingForward
      ? {
          first: event.pageSize,
          after: pagingInfo.after,
          last: null,
          before: null,
        }
      : {
          last: event.pageSize,
          before: pagingInfo.before,
          first: null,
          after: null,
        }
  }

  const forward = event.pageIndex > event.previousPageIndex
  return forward
    ? {
        first: event.pageSize,
        after: connection.edges.slice(-1)[0].cursor,
        last: null,
        before: null,
      }
    : {
        last: event.pageSize,
        before: connection.edges[0].cursor,
        first: null,
        after: null,
      }
}

export function pagingInfoFromQueryParams({
  first,
  after,
  last,
  before,
}: Params): PagingInfo {
  return {
    first: defaultTo(parseInt(first, 10), undefined),
    after,
    last: defaultTo(parseInt(last, 10), undefined),
    before,
  }
}

@Component({
  selector: 'kdgh-list-paging-controls',
  templateUrl: './list-paging-controls.component.html',
  styleUrls: ['./list-paging-controls.component.scss'],
})
export class ListPagingControlsComponent {
  @Input()
  connection: ListPagingControlsFragment

  @Input()
  entityType: string

  @Output()
  pageChange = new EventEmitter<PagingInfo>()

  nextPage() {
    const { edges, pageInfo } = this.connection
    this.pageChange.emit({
      first: pageInfo.size,
      after: edges.slice(-1)[0]?.cursor,
    })
  }

  previousPage() {
    const { edges, pageInfo } = this.connection
    this.pageChange.emit({
      last: pageInfo.size,
      before: edges[0]?.cursor,
    })
  }
}

import { CourseLevel } from 'src/generated/graphql'

export function levelToString(level: CourseLevel): string {
  switch (level) {
    case CourseLevel.One:
      return 'I'
    case CourseLevel.Two:
      return 'II'
    case CourseLevel.Three:
      return 'III'
  }
}

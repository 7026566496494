import {
  Component,
  HostBinding,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core'
import { ResourceLinkFragment } from '../../../generated/graphql'
import { assertType, levelToString } from '../../utils'

const resourceIconMap = {
  Person: 'account_circle',
  User: 'recent_actors',
  Payment: 'payment',
  ExpenseReceipt: 'receipt',
  Organization: 'business',
  DataProcessingAgreementChangeEvent: 'policy',
}

export function getResourceDisplayName(
  data: ResourceLinkFragment
): string | null {
  switch (data.__typename) {
    case 'Person':
      return data.displayName
    case 'Organization':
    case 'Course':
    case 'CoursePart':
    case 'CourseEvent':
    case 'CourseAdminGroup':
    case 'CourseOptionDescriptor':
      return data.name
    case 'AdminAppUser':
    case 'MemberPortalUser':
      return data.person.displayName
    case 'CourseAdminFile':
      return data.adminGroup.name
    case 'CourseParticipationCertificate':
      const { level, part, year } = data.courseSummary
      return `${year} - ${levelToString(level)} - ${part}`
    case 'OrganizationMembership':
      return data.organization.name
    default:
      return null
  }
}

export function getResourceURL(data: ResourceLinkFragment): string | null {
  switch (data.__typename) {
    case 'Person':
      return getPersonURL(data)
    case 'AdminAppUser':
      return getAdminAppUserURL(data)
    case 'MemberPortalUser':
      return getMemberPortalUserURL(data)
    case 'CourseAdminGroup':
      return getCourseAdminGroupURL(data)
    case 'CourseAdminFile':
      return getCourseAdminFileURL(data)
    case 'Organization':
      return getOrganizationURL(data)
    case 'OrganizationMembership':
      return getOrganizationMembershipURL(data)
    case 'CourseParticipationCertificate':
      return getCourseParticipationCertificateURL(data)
    default:
      return null
  }
}

function getPersonURL(data: ResourceLinkFragment) {
  return `/persons/${data.id}`
}

function getCourseAdminGroupURL(data: ResourceLinkFragment) {
  const {
    id: groupId,
    course: {
      organization: { id: orgId },
    },
  } = assertType(data, 'CourseAdminGroup')

  return `/organizations/${orgId}/courses/adminGroups/${groupId}`
}

function getCourseAdminFileURL(data: ResourceLinkFragment) {
  const {
    id: fileId,
    adminGroup: {
      id: groupId,
      course: {
        organization: { id: orgId },
      },
    },
  } = assertType(data, 'CourseAdminFile')

  return `/organizations/${orgId}/courses/adminGroups/${groupId}/${fileId}`
}

function getOrganizationURL(data: ResourceLinkFragment) {
  return `/organizations/${data.id}/dashboard`
}

function getOrganizationMembershipURL(data: ResourceLinkFragment) {
  const {
    id,
    person: { id: personId },
  } = assertType(data, 'OrganizationMembership')
  return `/persons/${personId}/memberships/${id}`
}

function getCourseParticipationCertificateURL(data: ResourceLinkFragment) {
  const {
    id,
    person: { id: personId },
  } = assertType(data, 'CourseParticipationCertificate')
  return `/persons/${personId}/certificates/${id}`
}

function getAdminAppUserURL(data: ResourceLinkFragment) {
  const {
    person: { id },
  } = assertType(data, 'AdminAppUser')
  return `/persons/${id}/user-account`
}

function getMemberPortalUserURL(data: ResourceLinkFragment) {
  const {
    person: { id },
  } = assertType(data, 'MemberPortalUser')
  return `/persons/${id}/user-account`
}

@Component({
  selector: 'kdgh-resource-link',
  templateUrl: './resource-link.component.html',
  styleUrls: ['./resource-link.component.scss'],
})
export class ResourceLinkComponent implements OnChanges {
  @Input()
  data: ResourceLinkFragment

  @Input()
  deleted = false

  @HostBinding('class.is-link')
  get isLink(): boolean {
    return !!this.url
  }

  url: string | null = null

  displayName: string | null = null

  icon: string | null = null

  ngOnChanges(changes: SimpleChanges): void {
    this.icon = resourceIconMap[this.data.__typename] ?? null

    if (!this.deleted) {
      this.url = getResourceURL(this.data)
      this.displayName = getResourceDisplayName(this.data)
    } else {
      this.url = null
      this.displayName = null
    }
  }
}

import { Directive, forwardRef } from '@angular/core'
import { ControlContainer, NgForm } from '@angular/forms'

export const formProvider: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => NgForm),
}

@Directive({
  selector: '[kdghProvideParentForm]',
  providers: [formProvider],
})
export class ProvideParentFormDirective {}

import { Directive } from '@angular/core'
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms'
import { KdghValidators } from './validators'

@Directive({
  /* eslint-disable @angular-eslint/directive-selector */
  selector:
    '[required][ngModel],[required][formControl],[required][formControlName]',
  providers: [
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: RequiredNotBlankValidator,
    },
  ],
})
export class RequiredNotBlankValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    return KdghValidators.notBlank(control)
  }
}

<ng-container *ngIf="organizations | async as orgs">
  <!-- Organization Link -->
  <a
    *ngIf="getSelectedOrganization(orgs) as org; else noOrgSelected"
    class="organization-link"
    routerLink="/organizations/{{
      organizationService.selectedOrganizationId
    }}/dashboard"
  >
    {{ org.name }}
  </a>

  <ng-template #noOrgSelected>
    <button class="no-organization-selected" disabled mat-button>
      Organisation auswählen
    </button>
  </ng-template>

  <div class="flex-spacer"></div>

  <!-- Organization Selection Menu -->
  <button
    class="organization-menu-btn"
    [matMenuTriggerFor]="organizationsMenu"
    aria-label="Organisationen"
    mat-icon-button
  >
    <mat-icon>expand_more</mat-icon>
  </button>

  <mat-menu #organizationsMenu xPosition="before">
    <!-- Existing Organisations -->

    <button
      (click)="selectOrganization(org.id)"
      *ngFor="let org of orgs"
      [class.is-active]="getSelectedOrganization(orgs)?.id === org.id"
      class="organization-menu-link"
      mat-menu-item
    >
      {{ org.name }}
    </button>

    <mat-divider *ngIf="orgs.length !== 0"></mat-divider>

    <button
      (click)="openSettingsDialog()"
      [kdghEnableFor]="['Admin']"
      mat-menu-item
    >
      Organisation anlegen
    </button>
  </mat-menu>
</ng-container>

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { from, Observable, of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { environment } from '../../../environments/environment'
import { AuthService } from './auth.service'

@Injectable({
  providedIn: 'root',
})
export class AccessTokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return (
      this.shouldSendAccessToken(req)
        ? from(this.authService.getAccessToken())
        : of(null)
    ).pipe(
      map(accessToken => {
        if (accessToken) {
          return req.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
        }

        return req
      }),
      mergeMap(reqWithAccessToken => next.handle(reqWithAccessToken))
    )
  }

  private shouldSendAccessToken(req: HttpRequest<any>): boolean {
    return req.url.startsWith(environment.apiBase)
  }
}

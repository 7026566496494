
// DO NOT EDIT. This is a generated file.
// tslint:disable

export const BUILD_INFO = {
  "name": "admin-ui",
  "version": "1.26.0",
  "date": "2023-12-03T17:00:52.621Z",
  "branch": "staging",
  "rev": "79a8f33075",
  "tag": null,
  "commitsSinceLastTag": null,
  "authorDate": null,
  "buildNumber": null
};

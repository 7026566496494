import { Directive, forwardRef } from '@angular/core'
import { ControlContainer, NgModelGroup } from '@angular/forms'

export const modelGroupProvider: any = {
  provide: ControlContainer,
  useExisting: forwardRef(() => NgModelGroup),
}

@Directive({
  selector: '[kdghProvideParentFormGroup]',
  providers: [modelGroupProvider],
})
export class ProvideParentFormGroupDirective {}

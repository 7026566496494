<div class="wrapper">
  <div class="label kdgh-label">
    <ng-content></ng-content>
  </div>

  <div class="controls kdgh-level">
    <ng-container *ngIf="!isUploading">
      <button
        mat-raised-button
        color="accent"
        class="upload-trigger"
        (click)="fileInput.click()"
        *ngIf="!connector.exists"
      >
        Hochladen
      </button>

      <button
        mat-button
        class="upload-trigger"
        (click)="connector.open()"
        *ngIf="connector.exists"
      >
        Herunterladen
      </button>

      <button
        mat-button
        *ngIf="connector.exists"
        (click)="connector.delete()"
        color="warn"
      >
        Löschen
      </button>
    </ng-container>

    <mat-progress-bar [value]="progress" *ngIf="isUploading">
    </mat-progress-bar>
  </div>

  <input
    type="file"
    class="file-input"
    #fileInput
    [accept]="accept"
    (change)="onFileInputChange()"
  />
</div>

import moment, { Moment } from 'moment'

export function toLocalDate(
  date: string | Date | Moment | null
): string | null {
  if (date === null) {
    return null
  }
  return moment(date).format('YYYY-MM-DD')
}

import { Component, Input } from '@angular/core'
import { MoneyCompFragment } from '../../../../generated/graphql'

@Component({
  selector: 'kdgh-money',
  templateUrl: './money.component.html',
  styleUrls: ['./money.component.scss'],
})
export class MoneyComponent {
  @Input()
  money: MoneyCompFragment
}

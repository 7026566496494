import {
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core'
import { combineLatest, ReplaySubject, Subscription } from 'rxjs'
import { filter, map, mapTo, startWith, switchMap } from 'rxjs/operators'
import { SiblingControlsDirective } from './sibling-controls.directive'

export class IfHasErrorContext {
  $implicit: any

  constructor(error: any) {
    this.$implicit = error
  }
}

@Directive({
  selector: '[kdghIfHasError]',
})
export class IfHasErrorDirective implements OnInit, OnDestroy {
  private errorCodeObs = new ReplaySubject<string>(1)
  private subscription: Subscription

  constructor(
    private siblingControls: SiblingControlsDirective,
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<IfHasErrorContext>
  ) {}

  @Input('kdghIfHasError')
  set errorCode(errorCode: string) {
    this.errorCodeObs.next(errorCode)
  }

  ngOnInit(): void {
    // Wait for ViewChildren of SiblingControls to become available
    setTimeout(() => this.setupSubscription())
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

  private setupSubscription() {
    const ngControls = this.siblingControls.ngControls

    const ngControlsObs = ngControls.changes.pipe(
      startWith(ngControls),
      mapTo(ngControls)
    )

    const ngControlObs = ngControlsObs.pipe(
      map(curNgControls => curNgControls.first),
      filter(ngControl => !!ngControl)
    )

    const isInvalidObs = ngControlObs.pipe(
      switchMap(ngControl =>
        ngControl.statusChanges.pipe(startWith(ngControl), mapTo(ngControl))
      ),
      map(ngControl => ngControl.invalid)
    )

    this.subscription = combineLatest([
      isInvalidObs,
      ngControlObs,
      this.errorCodeObs,
    ]).subscribe(([isInvalid, ngControl, errorCode]) => {
      this.viewContainerRef.clear()

      if (isInvalid) {
        const error = ngControl.getError(errorCode)
        if (error) {
          this.viewContainerRef.createEmbeddedView(
            this.templateRef,
            new IfHasErrorContext(error)
          )
        }
      }
    })
  }
}

import { Injectable } from '@angular/core'
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { InternalRefetchQueryDescriptor } from '@apollo/client/core'
import { PureQueryOptions } from '@apollo/client/core'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { DeleteOrganizationGQL } from '../../../generated/graphql'
import {
  OrganizationSettingsDialogComponent,
  OrganizationSettingsDialogComponentConfig,
} from './organization-settings-dialog/organization-settings-dialog.component'

const localStorageSelectOrganizationKey = `${environment.appName}:selectedOrganizationId`

export interface OrganizationSettingsDialogOptions {
  organizationId: string
}

@Injectable()
export class OrganizationService {
  private windowSelectedOrganizationId: string | null = null

  get selectedOrganizationId(): string | null {
    return (
      this.windowSelectedOrganizationId ||
      this.localStorageSelectedOrganizationId
    )
  }

  private registeredRefetchQueries: InternalRefetchQueryDescriptor[] = []

  private get localStorageSelectedOrganizationId(): string | null {
    return localStorage.getItem(localStorageSelectOrganizationKey)
  }

  constructor(
    private deleteMutation: DeleteOrganizationGQL,
    private matDialog: MatDialog,
    private router: Router
  ) {}

  get hasSelectedOrganization() {
    return this.selectedOrganizationId !== null
  }

  /**
   * Checks if an Organization with selectedOrganizationId exists and if not clears
   * selectedOrganizationId.
   *
   * @param ids the ids of the existing Organizations
   */
  verifySelectedOrganization(ids: string[]) {
    if (this.windowSelectedOrganizationId) {
      if (!ids.some(id => id === this.windowSelectedOrganizationId)) {
        this.windowSelectedOrganizationId = null
      }
    }

    if (this.localStorageSelectedOrganizationId) {
      if (!ids.some(id => id === this.localStorageSelectedOrganizationId)) {
        this.clearLocalStorageSelectedOrganization()
      }
    }
  }

  private clearLocalStorageSelectedOrganization() {
    localStorage.removeItem(localStorageSelectOrganizationKey)
  }

  async selectOrganization(id: string): Promise<void> {
    this.windowSelectedOrganizationId = id
    localStorage.setItem(localStorageSelectOrganizationKey, id)
    await this.router.navigate(['/organizations', id])
  }

  openNewOrganizationDialog(): MatDialogRef<any, string> {
    return this._openSettingsDialog({
      refetchQueries: this.registeredRefetchQueries,
    })
  }

  openSettingsDialog(
    options: OrganizationSettingsDialogOptions
  ): MatDialogRef<any> {
    return this._openSettingsDialog({
      organizationId: options.organizationId,
      refetchQueries: this.registeredRefetchQueries,
    })
  }

  deleteOrganization(id: string): Observable<void> {
    return this.deleteMutation
      .mutate({ id }, { refetchQueries: this.registeredRefetchQueries })
      .pipe(map(() => null))
  }

  registerQueryForUpdate(query: string | PureQueryOptions) {
    this.registeredRefetchQueries.push(query)
  }

  private _openSettingsDialog(
    config: OrganizationSettingsDialogComponentConfig
  ) {
    return this.matDialog.open(OrganizationSettingsDialogComponent, {
      data: config,
      width: 'calc(100vw - 2rem)',
      maxWidth: '48rem',
    })
  }
}

import { Component, OnInit } from '@angular/core'
import { AuthService } from '../auth.service'

@Component({
  selector: 'kdgh-auth-callback',
  templateUrl: './auth-callback.page.html',
  styleUrls: ['./auth-callback.page.scss'],
})
export class AuthCallbackPageComponent implements OnInit {
  constructor(private authService: AuthService) {}

  async ngOnInit() {
    await this.authService.handleCallback()
  }
}

<ng-container kdghProvideParentForm>
  <kdgh-form-section>
    <kdgh-form-section-title>Name</kdgh-form-section-title>

    <!--  name -->
    <mat-form-field>
      <mat-label>Name</mat-label>

      <input
        #nameControl="ngModel"
        matInput
        maxlength="256"
        name="name"
        ngModel
        required
        type="text"
      />

      <mat-hint align="end">
        <kdgh-input-length-counter></kdgh-input-length-counter>
      </mat-hint>

      <mat-hint *kdghIfHasError="'notUnique'">
        Dieser Name wird bereits von einer anderen Organisation verwendet.
      </mat-hint>
    </mat-form-field>

    <kdgh-form-field-description>
      Dieser Name wird in der Benutzeroberfläche verwendet. Der selbe Name kann
      nicht von zwei Organisationen gleichzeitig verwendet werden.
    </kdgh-form-field-description>

    <!--  officialName -->
    <mat-form-field>
      <mat-label>Offizieller Name</mat-label>

      <input
        matInput
        maxlength="1000"
        name="officialName"
        ngModel
        required
        type="text"
      />

      <mat-hint align="end">
        <kdgh-input-length-counter></kdgh-input-length-counter>
      </mat-hint>
    </mat-form-field>

    <kdgh-form-field-description>
      Dieser Name ist der offizielle, volle Name der Organisation, der bei einem
      Verein zum Beispiel, in das Vereinsregister eingetragen wurde. Er wird
      unter anderem bei dem generieren von offiziellen Dokument verwendet.
    </kdgh-form-field-description>
  </kdgh-form-section>

  <kdgh-form-section>
    <kdgh-form-section-title>Adresse</kdgh-form-section-title>

    <!--  address-->
    <kdgh-form-field-description>
      Diese Adresse ist die offizielle Anschrift der Organisation.
    </kdgh-form-field-description>

    <kdgh-postal-address-form ngModelGroup="address"></kdgh-postal-address-form>
  </kdgh-form-section>

  <kdgh-form-section>
    <kdgh-form-section-title>Quittungen</kdgh-form-section-title>

    <!--  receiptsFooter -->

    <mat-form-field>
      <mat-label>Fußzeile</mat-label>

      <textarea
        matInput
        maxlength="10000"
        name="receiptsFooter"
        ngModel
        rows="10"
        type="text"
      >
      </textarea>

      <mat-hint align="end">
        <kdgh-input-length-counter></kdgh-input-length-counter>
      </mat-hint>
    </mat-form-field>
  </kdgh-form-section>

  <kdgh-form-section>
    <kdgh-form-section-title>Mitgliedschaftsbeitrag</kdgh-form-section-title>

    <mat-checkbox ngModel name="membershipRequiresContribution">
      Mitgliedschaft is beitragspflichtig
    </mat-checkbox>
  </kdgh-form-section>
</ng-container>
